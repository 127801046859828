import React, {
	useState,
	useMemo,
	useRef,
	useEffect,
	useCallback,
} from "react";
import "./styles.scss";
import { ConnectionTypes, dataBrowserTabs, dataTabsList, sourceTypeNameMapping, SourceTypes } from "./enums";
import classNames from "classnames";
import {
	ConnectionsTreeview,
	TableMetaData,
} from "./treeviews/connections-treeview";
import {
	RecordsInfo,
	RecordsInfoResponse,
	DataSourceHandler,
	PiRequestQuery,
	NiFiManagedNodesResponse,
	NiFiManagedConnectionInfo,
	AuditRequestHistoryType,
	ProgressHistoryType,
	ProgressLogsResponse,
	FileInfo
} from "../../api/data-source-handler";
// import { CellMeasurer, CellMeasurerCache, GridCellRenderer, MultiGrid, AutoSizer  } from 'react-virtualized';
import { PaginateRef } from "../../components/paginate";
// import Form, { SelectField } from '../../components/form';
import { ColumnManagementRhs } from "./column-management-rhs";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/types";
import { _selectoptionType } from "../../components/form/select-field";
import { QueryEditorBuilderModal } from "./modals/query-editor-builder";
import { setActionType, toggleDataBrowserModal } from "../../store/datasources";
import {
	Row,
	Col,
	DatePicker,
	Input,
	Button,
	Icon,
	Select,
	Form as AntForm,
} from "antd";
import { ShowWhenTrue } from "../../helpers";
import {
	ActiveSortColumn,
	AdjustToCellSizeTable,
} from "../../components/table/";
import {
	NewEditPiRequestModal,
	NewEditPiRequestModalTypes,
} from "./modals/new-edit-pi-request";
import { TablePagination } from "../../components/table/TablePagination";
import {
	PiManagedRequestsTable,
	PiRequestInfo,
	piRequestStateOptions,
	PiRequestStates,
} from "./pi-managed-requests-table";
import { InPageSpinner } from "../../components/spinners/in-page-spinner";
import moment from "moment";
import {
	RequestLogsModal,
	SelectedPropertyForRequestLogModal,
} from "./modals/request-logs";
import { useDebounce } from "rooks";
import styles from "./styles.module.scss";
import { RefreshedAgo } from "./RefreshedAgo";
import { BottomPanel } from "./components/BottomPanel";
import { Icon as DsIcon } from "./components/Icon";
import CustomDateTimePicker, { DatePickerValue } from "@components/form/date-picker";
import { Checkbox } from "@components/form/elements";
import get from "lodash/get";
import { ViewRequestDataModal } from "./modals/request-data-logs";
import { infoAlert } from "@components/toastify/notify-toast";
import { isEmpty } from "lodash";
import { ViewLogsRequestDataModal } from "./modals/view-audit-request--logs";
import { ViewProgressRequestDataModal } from "./modals/view-progress-request";

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = "MM/DD/YYYY HH:mm";

type PiRequestModalInfo = {
	type: NewEditPiRequestModalTypes;
	selectedPiRequestInfo: PiRequestInfo | null;
	property: SelectedPropertyForRequestLogModal;
};

type NodeStatus = {
	online: boolean;
	error: string[];
  } | undefined;

const REQUEST_TIME_RANGES = [
	{
		key: "all-time",
		label: "All time",
		from: null,
		to: null,
	},
	{
		key: "last-24-hours",
		label: "Last 24 hours",
		from: moment().subtract(1, "days").toISOString().split('.')[0],
		to: null,
	},
	{
		key: "last-72-hours",
		label: "Last 72 hours",
		from: moment().subtract(3, "days").toISOString().split('.')[0],
		to: null,
	},
	{
		key: "last-week-days",
		label: "Last Week",
		from: moment().subtract(1, "weeks").toISOString().split('.')[0],
		to: null,
	},
	{
		key: "custom",
		label: "Custom Date",
	}
];

const numberOfStates = Object.keys(PiRequestStates).length;

const sinkType = { 
	"AzureEventHub": "Azure Event Hub",
	"S3": "S3",
	"Kinesis": "Kinesis",
	"AzureBlob": "Azure Blob",
	"ADLSGen2":"ADLS Gen 2"	
}

const DataSourceBrowser: React.FC = () => {
	const [activeDataBrowserTab, setActiveDataBrowserTab] = useState(
		dataBrowserTabs.connections
	);
	const [recordsInfo, setRecordsInfo] = useState<{
		content: RecordsInfo;
		totalCount: number;
	}>({ totalCount: 0, content: [] });
	const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState(25);
	const {
		isColumnManagementMinimized,
		showConnectionsTreeview,
	} = useSelector((store: RootState) => store.NewDataSourceReducer);
	const { allUsersList } = useSelector(
		(store: RootState) => store.AccountReducer
	);
	const [activeRecordsMetadata, setActiveRecordsMetadata] = useState<
		TableMetaData
	>({ connectionId: 0, tableName: "", connectionName: "", sourceType: "" });
	const [searchString, setSearchString] = useState("");
	const [allPIServerList, setAllPIServerList] = useState<_selectoptionType[]>(
		[]
	);
	const [piServerLoading, setPiServerLoading] = useState(true);
	const [piFilterLoading, setPiFilterLoading] = useState(false);
	const [piSelectedStates, setPiSelectedStates] = useState<string[]>(Object.keys(PiRequestStates));
	const [sinkSelectedStates, setSinkSelectedStates] = useState<string[]>(["ADLSGen2","AzureBlob","AzureEventHub","Kinesis","S3"]);
	const [isViewDataModalOpen,setIsViewDataModalOpen] = useState(false);
	const [viewDataModal,setViewDataModal] = useState<string[]>([]);
	const [isViewLogsModalOpen,setIsViewLogsModalOpen] = useState(false);
	const [viewLogsModalData,setViewLogsModalData] = useState<AuditRequestHistoryType[]>([]);
	const [progressModalOpen,setProgressModalOpen] = useState(false);
	const [viewProgressModalData,setViewProgressModalData] = useState<FileInfo[]>([]);
	const [currProgressRequest,setCurrProgressRequest] = useState<PiRequestInfo | undefined>(undefined)
	const [currPiRequest,setCurrPiRequest] = useState<PiRequestInfo | undefined>(undefined)
	
	const paginateRef = useRef<PaginateRef>(null);
	const dispatch = useDispatch();
	const state: any = {
		dateRange: [],
		tags: "",
		serverName: "",
		startDate: "",
		endDate: "",
	};
	const [piRequestModalInfo, setPiRequestModalInfo] = useState<
		PiRequestModalInfo
	>({
		type: "new",
		selectedPiRequestInfo: null,
		property: "warning",
	});

	const [bottomTabOpen, setBottomTabOpen] = useState(false);
	const [bottomTabIndex, setBottomTabIndex] = useState(0);
	
	const [isConnectionErrorRead, setIsConnectionErrorRead] = useState(false);
	const [showAllLogsView, setAllLogsView] = useState(false);
	const [piNodes, setPiNodes] = useState<any>({});
	const [nodeStatus, setNodeStatus] = useState<NodeStatus>(undefined)
	const [sinkTypeOptions, setSinkTypeOptions] = useState<string[]>(
		[]
	);
	useEffect(() => {
		if(piNodes && activeRecordsMetadata) {
			if(activeRecordsMetadata.connectionType=="Edge_Managed"){
			const activeSourceTypeNodes=piNodes?.Edge[activeRecordsMetadata.sourceType][activeRecordsMetadata?.connectionType]
			if(Array.isArray(activeSourceTypeNodes)) {
				const activeConnection = activeSourceTypeNodes.find((node:any) => node.nodeName === activeRecordsMetadata.managedNodeInfo?.nodeName) as NiFiManagedConnectionInfo;
				if(activeConnection) {
					if(nodeStatus?.online && !activeConnection.node.online) {
						setIsConnectionErrorRead(false);
					}
					setNodeStatus({
						online: activeConnection.node.online,
						error: activeConnection.node.error || [],
					});
				}	
			}
		}else if(activeRecordsMetadata.connectionType=="Edge_Managed_Oracle"){
			setNodeStatus({
				online: piNodes.Relational?.Edge_Managed_Oracle?.[0]?.node?.online ?? false,
				error: [],
			});
		}
		else if(activeRecordsMetadata.connectionType=="Edge_Managed_FTP"){
			const a=piNodes.FTP?.Edge_Managed_FTP || []
			const active_Connection=a.find((item:any) => item.name === activeRecordsMetadata.connectionName);
			if (active_Connection) {
				setNodeStatus({
					online: active_Connection.node?.online ?? false,
					error: [],
				});
			} else {
				setNodeStatus({
					online: false,
					error: [],
				});
			}
		}	
		}else{
			setNodeStatus(undefined)
		}
	}, [piNodes, activeRecordsMetadata]);

	useEffect(()=>{
		DataSourceHandler.GetSinkType((res) => {
			// TODO: remove after demo
			setSinkTypeOptions(
				res.sort().filter((s: string)=>![""].includes(s)).map((_res: any) => {
					return _res
				})
			);
		});
	},[])

	const tableContent = useMemo(
		() =>
			recordsInfo.content.map((cont: RecordsInfo) => {
				const user = allUsersList.find(
					(user) => user.email === cont.createdBy
				);
				cont.userName = user
					? `${user.firstName} ${user.lastName}`
					: cont.createdBy;
				return cont;
			}),
		[recordsInfo]
	);

	const sourceDataForTable: string[][] = useMemo(() => {
		if (activeRecordsMetadata.connectionName === "PI") {
			recordsInfo.content.unshift([
				"Tag name",
				"Date",
				"Value",
				"Quality",
			]);
			// gridRef.current?.forceUpdateGrids();
			// cache.clearAll();
			return recordsInfo.content;
		}
		if (recordsInfo.totalCount !== 0 && recordsInfo.content.length !== 0) {
			const _recordsInfo = recordsInfo.content.map(
				(_record: { [s: string]: any }) =>
					Object.values(_record).map((_value) => {
						if (typeof _value === "object") {
							_value = JSON.stringify(_value);
						}
						return _value;
					})
			);
			// Add column names
			_recordsInfo.unshift(Object.keys(recordsInfo.content[0]));
			// if content count is same between renders, it doesnt re-render
			// gridRef.current?.forceUpdateGrids();
			// // reset cell and column width
			// cache.clearAll();
			return _recordsInfo;
		}
		return [];
	}, [recordsInfo]);

	const handleSetRecordsInfo = (data: RecordsInfoResponse) => {
		setRecordsInfo({
			totalCount: data.totalElements,
			content: data.content,
		});
	};

	const retrieveRecords = ({
		connectionId = activeRecordsMetadata.connectionId,
		tableName = activeRecordsMetadata.tableName,
		pageNo = 1,
		pageSize = numberOfItemsPerPage,
		searchText = searchString,
	}) => {
		if(activeRecordsMetadata.connectionType !== 'Edge_Managed_Oracle' && activeRecordsMetadata.connectionType !== 'Edge_Managed_FTP'){
		DataSourceHandler.GetRecords(
			{ connectionId, tableName, pageNo, pageSize, searchText },
			handleSetRecordsInfo
		);
	}
	};

	const handlePageChange = (pageNo: number) => {
		if (isNifiManagedConnection) {
			handleSetPiRequestQueryInfo({ page: pageNo });
		} else {
			retrieveRecords({ pageNo });
		}
	};

	const getPiServerList = (tableInfo: TableMetaData) => {
		setPiServerLoading(true);
		DataSourceHandler.GetPIServer(
			tableInfo.connectionId,
			(data: any) => {
				setAllPIServerList(
					data.all_servers_list.map((_data: any) => {
						return {
							label: _data,
							value: _data,
						};
					})
				);
				setPiServerLoading(false);
			},
			() => {
				setPiServerLoading(false);
			}
		);
	};

	const handleGetRecords = (tableInfo: TableMetaData) => {
		setActiveRecordsMetadata(tableInfo);
		if(tableInfo.connectionType !== ConnectionTypes['NiFi Managed'] && tableInfo.connectionType !== 'Edge_Managed_Oracle' && tableInfo.connectionType !== 'Edge_Managed_FTP') {
			if (tableInfo.connectionType === "PI") {
				getPiServerList(tableInfo);
			} else {
				retrieveRecords(tableInfo);
				paginateRef.current?.resetPagesToStartPage();
			}	
		}
		
	};

	const handlePageOptionClick = (newPageSize: number) => {
		setNumberOfItemsPerPage(newPageSize);
		if (isNifiManagedConnection) {
			// page number is reset to 1 on changing page size
			handleSetPiRequestQueryInfo({ size: newPageSize, page: 1 });
		} else {
			retrieveRecords({ pageSize: newPageSize });
		}
	};

	const _retrieveRecordsUsingSearch = useDebounce((searchText: string) => {
		retrieveRecords({ searchText });
	}, 400);

	const handleQueryEditorBuilderSubmit = (query: string) => {
		return query;
	};

	const getPIData = (dat: any = "") => {
		dat.preventDefault();
		setPiFilterLoading(true);
		DataSourceHandler.GetPIData(
			activeRecordsMetadata.connectionId,
			{
				attr_type: "PI_Server",
				pi_server: state.serverName,
				Value_type: "RecordedValues",
				custom_start_time: state.startDate,
				custom_end_time: state.endDate,
				tag_file_path: state.tags,
				site_id: 2,
			},
			(data: any) => {
				setPiFilterLoading(false);
				setRecordsInfo({
					totalCount: data.tags.length,
					content: data.tags,
				});
			},
			() => {
				setPiFilterLoading(false);
			}
		);
	};

	const handleResetRecords = () => {
		setRecordsInfo({ content: [], totalCount: 0 });
		setActiveRecordsMetadata({
			connectionId: 0,
			tableName: "",
			connectionName: "",
			sourceType: ""
		});
	};

	const { isNifiManagedConnection, showBottomHelpTab } = useMemo(
		() => {
			const isNifiManagedConnection = 
			activeRecordsMetadata.connectionType === ConnectionTypes["NiFi Managed"] ||
			activeRecordsMetadata.connectionType === ConnectionTypes["NiFi Managed FTP"];
					  return {
				isNifiManagedConnection,
				showBottomHelpTab: isNifiManagedConnection && activeRecordsMetadata.sourceType === SourceTypes.OSIPI
			}
		},
		[activeRecordsMetadata]
	);

	const [lastRefreshedAt, setLastRefreshedAt] = useState(new Date());

	const [showRefreshPiLoader, toggleRefreshPiLoader] = useState(false);
	const {
		value: createdDateRange,
		setValue: setCreatedDateRange,
	} = CustomDateTimePicker.useCustomRangeController();
	const [showDateRange, setShowDateRange] = useState(false);

	const [activeSortColumn, setActiveSortColumn] = useState<ActiveSortColumn>({
		columnKey: "updated",
		type: "DESC",
	});

	const [piRequestQueryInfo, setPiRequestQueryInfo] = useState<
		PiRequestQuery
	>({
		page: 1,
		size: numberOfItemsPerPage,
		sort: `${
			activeSortColumn.columnKey
		},${activeSortColumn.type.toLowerCase()}`,
		state: piRequestStateOptions.map((state) => state.value),
		filter: "",
		creationDateTo: null,
		creationDateFrom: null,
	});

	const getNodePiRequests = useDebounce(
		(nodeName: string, showLoader = true) => {
			if (showLoader) {
				toggleRefreshPiLoader(true);
			}
			if (activeRecordsMetadata.connectionType === "Edge_Managed" && activeRecordsMetadata.sourceType === "OPCUA") {
				piRequestQueryInfo.sinkKind = ['']; 
			}
			
			DataSourceHandler.GetRequestsOfPiNode(
				nodeName,
				piRequestQueryInfo,
				(res) => {
					setRecordsInfo({
						content: res.requests.map((request) => ({
							...request,
							created: moment(request.created).format("LLL"),
							updated: moment(request.updated).format("LLL"),
						})),
						totalCount: res.totalItems,
					});
					// paginateRef.current?.resetPagesToStartPage();
					toggleRefreshPiLoader(false);
					setLastRefreshedAt(new Date());
				}
			);
		},
		400
	);

	useEffect(() => {
		if (isNifiManagedConnection && activeRecordsMetadata.managedNodeInfo?.nodeName) {
			getNodePiRequests(activeRecordsMetadata.managedNodeInfo.nodeName);
		}else if(activeRecordsMetadata.connectionType == "Edge_Managed_Oracle"){
			getNodePiRequests(activeRecordsMetadata.managedNodeInfo?.nodeName);
		}else if(activeRecordsMetadata.connectionType == 'Edge_Managed_FTP'){
			getNodePiRequests(activeRecordsMetadata.managedNodeInfo?.nodeName);
		}else if(activeRecordsMetadata.connectionType == 'Edge_Managed'){
			getNodePiRequests(activeRecordsMetadata.nodeName);
		}
	}, [piRequestQueryInfo, activeRecordsMetadata, isNifiManagedConnection]);

	useEffect(() => {
		handleSetPiRequestQueryInfo({state: (piSelectedStates.length ? piSelectedStates : ["NONE"]) as PiRequestStates[]});
	},[piSelectedStates]);

	useEffect(() => {
			handleSetSinkRequestQueryInfo({sinkKind: (sinkSelectedStates.length ? sinkSelectedStates : ["NONE"]) as PiRequestStates[]});
	},[sinkSelectedStates]);

	const showCreatePiRequestModal = () => {
		dispatch(toggleDataBrowserModal("newPiRequest", true));
		setPiRequestModalInfo({
			selectedPiRequestInfo: null,
			type: "new",
			property: piRequestModalInfo.property,
		});
	};

	const refreshActivePrRecords = (showLoader = false) => {
		if(activeRecordsMetadata.managedNodeInfo?.nodeName)
			getNodePiRequests(activeRecordsMetadata.managedNodeInfo.nodeName, showLoader);
	};

	const handleSetPiRequestQueryInfo = (
		updatedQuery: Partial<PiRequestQuery>
	) => {
		setPiRequestQueryInfo((piRequestQueryInfo) => ({
			...piRequestQueryInfo,
			...updatedQuery,
		}));
	};

	const handleSetSinkRequestQueryInfo = (
		updatedQuery: Partial<PiRequestQuery>
	) => {
		setPiRequestQueryInfo((piRequestQueryInfo) => ({
			...piRequestQueryInfo,
			...updatedQuery,
		}));
	};

	const handleSearchTags = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);
		if (isNifiManagedConnection || activeRecordsMetadata.connectionType == "Edge_Managed_Oracle" || activeRecordsMetadata.connectionType == "Edge_Managed_FTP") {
			getNodePiRequests(activeRecordsMetadata.managedNodeInfo?.nodeName);
			handleSetPiRequestQueryInfo({ filter: e.target.value });
		} else {
			_retrieveRecordsUsingSearch(e.target.value);
		}
	};

	const handleViewRequest = (requestInfo: PiRequestInfo) => {
		dispatch(toggleDataBrowserModal("newPiRequest", true));
		setPiRequestModalInfo({
			selectedPiRequestInfo: requestInfo,
			type: requestInfo.state === PiRequestStates.New ? "edit" : "view",
			property: piRequestModalInfo.property,
		});
	};

	const handleViewData = (data:string[]) =>{
		setIsViewDataModalOpen(true);
		setViewDataModal(data);
	}

	const handleAuditLogsResponse=(data:AuditRequestHistoryType[])=>{
		if(isEmpty(data)){
			infoAlert("No logs found!");
		}else{
			setIsViewLogsModalOpen(true);
			setViewLogsModalData(data);
		}
		toggleRefreshPiLoader(false);
	}

	const handleProgressLogsResponse=(data: any)=>{
		if(isEmpty(data)){
			infoAlert("No logs found!");
		}else{
			setProgressModalOpen(true);
			setViewProgressModalData(data[0]?.fileInfo);
		}
		toggleRefreshPiLoader(false);
	}
	
	const handleViewLogs = (data:PiRequestInfo) =>{
		toggleRefreshPiLoader(true);
		DataSourceHandler.GetAuditReqHistory(`${data.id}`, (response:AuditRequestHistoryType[]) => {
            DataSourceHandler.GetAllConnectionsInfo(()=>handleAuditLogsResponse(response));
			
		});
		setCurrPiRequest(data);
	}

	const handleViewProgress = (data: any) => {
		toggleRefreshPiLoader(true);
		const requestId = `${data.id}`; 
		DataSourceHandler.GetProgressReqHistory(requestId, (response: ProgressLogsResponse[]) => {
		  handleProgressLogsResponse([response]);
		});
		setCurrProgressRequest(data);
	  };

	const onViewRequestDataClose =()=>{
		setIsViewDataModalOpen(false);
	}
	
	const onViewLogsRequestDataClose =()=>{
		setIsViewLogsModalOpen(false);
		setViewLogsModalData([]);
	}

	const onProgressRequestDataClose =()=>{
		setProgressModalOpen(false);
	}
	
	const handleViewRequestLog = (
		requestInfo: PiRequestInfo,
		property: SelectedPropertyForRequestLogModal
	) => {
		dispatch(toggleDataBrowserModal("requestLogs", true));
		setPiRequestModalInfo({
			selectedPiRequestInfo: requestInfo,
			type: piRequestModalInfo.type,
			property,
		});
	};

	const onRefreshBtnClick = () => {
		toggleRefreshPiLoader(true);
		refreshActivePrRecords(true);
	};

	const handleSetActiveSortColumn = useCallback(
		(activeSortColumn: ActiveSortColumn) => {
			setActiveSortColumn(activeSortColumn);
			handleSetPiRequestQueryInfo({
				sort: `${
					activeSortColumn.columnKey
				},${activeSortColumn.type.toLowerCase()}`,
			});
		},
		[]
	);

	const handleCreatedDate = useCallback((value: string) => {
		if(value === "custom") {
			setShowDateRange(true);
		} else {
			setShowDateRange(false);
			setCreatedDateRange(null);
		}

		const range = REQUEST_TIME_RANGES.find((r) => r.key === value);
		handleSetPiRequestQueryInfo({
			creationDateFrom: range?.from,
			creationDateTo: range?.to,
		});
	}, []);

	const handleSetCreatedDateRange = useCallback((value: DatePickerValue<'range'>) => {
		setCreatedDateRange(value)
		handleSetPiRequestQueryInfo({
			creationDateFrom: value?.[0]?.startOf('day').toISOString().split('.')[0] || null, 
			creationDateTo: value?.[1]?.endOf('day').toISOString().split('.')[0] || null, 
		})
	}, [])

	const showConnectionError = () => {
		setAllLogsView(false);
		dispatch(toggleDataBrowserModal("requestLogs", true));
		setPiRequestModalInfo({
			selectedPiRequestInfo: null,
			type: piRequestModalInfo.type,
			property: 'error'
		});
		setIsConnectionErrorRead(true);
	}

	const showConnectionLogs= () => {
		dispatch(toggleDataBrowserModal("requestLogs", true));
		setPiRequestModalInfo({
			selectedPiRequestInfo: null,
			type: piRequestModalInfo.type,
			property: 'warning'
		});
		setAllLogsView(true);
	}

	const handleNewConnectionClick = () => {
		dispatch(setActionType('NEW'))
		dispatch(toggleDataBrowserModal('newEditConnection', true));
	  };

	  let displayText: string;

if (activeRecordsMetadata.connectionType === ConnectionTypes["NiFi Managed"]) {
    displayText = `${sourceTypeNameMapping[activeRecordsMetadata.sourceType]} (Edge Managed)`;
} else if (activeRecordsMetadata.connectionType === 'Edge_Managed_Oracle') {
    displayText = `RDBMS (Edge Managed Oracle)`;
} else if(activeRecordsMetadata.connectionType === 'Edge_Managed_FTP'){
	displayText = `Object Storage (Edge Managed FTP)`;
} else {
    displayText = "Table";
}

	return (
		<section className="dataSourceBrowser__container">
			<div
				className={classNames("treeview__container", {
					"treeview--minimized": !showConnectionsTreeview,
				})}
			>
				<p className="LHS__title">Data Browser</p>
				<ul className="switchable__tabs__outer">
					{dataTabsList.map((tab) => (
						<li
							key={tab}
							className={classNames("switchable__tab", {
								"switchable__tab--active":
									activeDataBrowserTab === tab,
							})}
							onClick={() => setActiveDataBrowserTab(tab)}
						>
							{tab}
						</li>
					))}
				</ul>
				<ConnectionsTreeview
					handleGetRecords={handleGetRecords}
					handleResetRecords={handleResetRecords}
					handleNodeUpdate={setPiNodes}
				/>
			</div>
			<div
				className={classNames(
					"workflow__master__container dataSourceBrowser__outer",
					{ fullWidthMode: isColumnManagementMinimized }
				)}
			>
				{/* <CanvasTabsComponent
					handlePlusIconClick={() => {
						return;
					}}
					openTabs={new Map()}
					activeTab={0}
					switchTab={() => {
						return;
					}}
					closeTab={() => {
						return;
					}}
					updateOpenTabs={() => {
						return;
					}}
					showSavedStatus={false}
					hidePlusIcon
				/> */}

				<div
					className={classNames("dataSourceBrowser__canvas", {
						hidePadding: !activeRecordsMetadata.tableName,
						"dataSourceBrowser__canvas--with-bottom-tab": showBottomHelpTab,
					})}
				>
					{activeRecordsMetadata.tableName ? (
						<div className="innerCanvas">
							<div className="dataSourceBrowser__header">
								<ShowWhenTrue
									show={
										activeRecordsMetadata.connectionName !==
										"PI"
									}
								>
									<p className={classNames("noStatus__table__title", {
										"dataSourceBrowser__table__title": nodeStatus !== undefined,
										"connection-offline": nodeStatus !== undefined && !nodeStatus.online,
									})}>
										{displayText}
										:
										<span>
											&nbsp;
											{activeRecordsMetadata.connectionType === ConnectionTypes["NiFi Managed"] ?
											activeRecordsMetadata.tableName:`${activeRecordsMetadata.tableName}(${activeRecordsMetadata.managedNodeInfo?.nodeName})`}

										</span>
										{ isNifiManagedConnection &&
											<button className={classNames(styles.piConnectionErrorButton, {[styles.read]: isConnectionErrorRead})} onClick={showConnectionError}>
												<img src="/icons/data-browser/error.svg" alt="" />
											</button>
										}
										{ isNifiManagedConnection &&
											<button className={classNames(styles.piConnectionErrorButton)} onClick={showConnectionLogs}>
												<img src="/icons/data-browser/view.svg" alt="" />
											</button>
										}
										{/* <button
                                            className="btn-md btn-grey btn-openQueryModal"
                                            onClick={() => dispatch(toggleDataBrowserModal('queryEditorBuilder' ,true))}
                                        >
                                        Query
                                        </button> */}
									</p>
								</ShowWhenTrue>
								<ShowWhenTrue
									show={
										activeRecordsMetadata.connectionName ===
										"PI"
									}
								>
									<p>{activeRecordsMetadata.tableName}</p>
								</ShowWhenTrue>
								<ShowWhenTrue
									show={
										activeRecordsMetadata.connectionType !==
										ConnectionTypes["NiFi Managed"]
									}
								>
									<p className="dataSourceBrowser__header__connectionName">
										Connection:
										<span>
											&nbsp;
											{
												activeRecordsMetadata.connectionName
											}
										</span>
									</p>

								</ShowWhenTrue>

								{ isNifiManagedConnection && 
									<button
										className={styles.dataBrowserHelp}
										onClick={() => {
											setBottomTabOpen(true);
											setBottomTabIndex(0);
										}}
									>
										<DsIcon name="help" />
										Help
									</button>
								}
							</div>
							<ShowWhenTrue
								show={
									activeRecordsMetadata.connectionName ===
									"PI"
								}
							>
								<div
									className="dataSourceBrowser__header"
									style={{
										width: "100%",
										display: "inline-block",
									}}
								>
									<AntForm
										onSubmit={getPIData}
										//onFinish={onFinish}
										//onFinishFailed={onFinishFailed}
									>
										<Row gutter={[16, 16]}>
											<Col span={2}>
												<p className="dataSourceBrowser__table__title">
													Filter:
												</p>
											</Col>
											<Col span={6}>
												<Select
													placeholder="Select PI Server"
													style={{
														width: "100%",
													}}
													allowClear
													notFoundContent="No PI Server found"
													loading={piServerLoading}
													onChange={(_data) => {
														state.serverName = _data;
													}}
												>
													{allPIServerList.map(
														(d) => (
															<Option
																key={d.value}
															>
																{d.label}
															</Option>
														)
													)}
												</Select>
											</Col>
											<Col span={6}>
												<Input
													onChange={(e) => {
														state.tags =
															e.target.value;
													}}
													placeholder="Enter Tag(s) Name"
													prefix={
														<Icon
															type="search"
															style={{
																color: "white",
															}}
														/>
													}
												/>
											</Col>
											<Col span={6}>
												<RangePicker
													format={dateFormat}
													showTime={{
														format: "HH:mm",
													}}
													onChange={(_data) => {
														state.startDate = _data[0]?.format(
															"DD-MMM-YYYY HH:mm:ss"
														);
														state.endDate = _data[1]?.format(
															"DD-MMM-YYYY HH:mm:ss"
														);
													}}
													onOk={() => {
														return;
													}}
													style={{
														marginRight: "10px",
													}}
												/>
											</Col>
											<Col span={2}>
												<Button
													type="primary"
													loading={piFilterLoading}
													icon="search"
													htmlType="submit"
												>
													Search
												</Button>
											</Col>
										</Row>
									</AntForm>
								</div>
							</ShowWhenTrue>
							<div>
								<ShowWhenTrue
									show={
										activeRecordsMetadata.connectionName !==
										"PI"
									}
								>
									<div
										className={styles.piRequestMainActions}
									>
										<div className={styles.resultsCount}>
											<img
												src="/icons/data-browser/activity.svg"
												alt=""
											/>
											Results({recordsInfo.totalCount})
										</div>
										<ShowWhenTrue show={activeRecordsMetadata.connectionType === ConnectionTypes["NiFi Managed"] || activeRecordsMetadata.connectionType == 'Edge_Managed_Oracle' || activeRecordsMetadata.connectionType == 'Edge_Managed_FTP'}>
											<div className={styles.actions}>
												<button
													className="btn btn-yellow btn-md"
													onClick={
														showCreatePiRequestModal
													}
												>
													<img src="/icons/data-browser/add.svg" />
													<span>New</span>
												</button>
												<button
													className="btn btn-md btn-grey-transparent"
													id="refreshPiRequests"
													onClick={onRefreshBtnClick}
													disabled={showRefreshPiLoader}
												>
													{showRefreshPiLoader ? (
														<InPageSpinner
															size="XSmall"
															color="black"
														/>
													) : (
														<>
															<img src="/icons/data-browser/refresh.svg" />
															Refresh
														</>
													)}
												</button>
												<span className={styles.timeAgo}>
													<RefreshedAgo
														time={lastRefreshedAt}
													/>
												</span>
											</div>
										</ShowWhenTrue>
									</div>
									<ShowWhenTrue show={activeRecordsMetadata.connectionType === ConnectionTypes["NiFi Managed"] || activeRecordsMetadata.connectionType == 'Edge_Managed_Oracle' || activeRecordsMetadata.connectionType == 'Edge_Managed_FTP'}>
										<div className={styles.piRequestFilters}>
											<div>
												<label>Search</label>
												<input
													onChange={handleSearchTags}
													className="search_input"
													placeholder="Search by ID or Name or Request body"
													value={searchString}
												/>
											</div>
											{!(activeRecordsMetadata.connectionType === "Edge_Managed" && activeRecordsMetadata.sourceType === "OPCUA")
											&& (<div className={styles.piSelect}>
												<label>Filter by Sink</label>
												<div className={styles.piSelectStateWrapper}>
													<Select
														style={{width: '100%'}}
														size="large"
														optionLabelProp="label"
														mode="multiple"
														className="piStateSelect"
														value={sinkSelectedStates}
														showArrow
														dropdownRender={menu => (
															<>
																<div className={styles.piSelectSelectAll} onMouseDown={(e) => e.preventDefault()} onClick={
																	() => {
																		setSinkSelectedStates(sinkSelectedStates.length === sinkTypeOptions.length ? [] : Object.values(sinkTypeOptions));
																	}
																}>
																	<Checkbox
																		partiallySelected={sinkSelectedStates.length !== sinkTypeOptions.length && sinkSelectedStates.length > 0}
																		checked={sinkSelectedStates.length === sinkTypeOptions.length}
																		color="gold"
																		onClick={(e) =>{e.preventDefault()}}
																	/>
																	Select All
																</div>
																{menu}
															</>
														)}
														suffixIcon={<img src="/icons/data-browser/down.svg"/>}
														onChange={(v: string[]) => {
															setSinkSelectedStates(v);
														}}
													>
														{
															sinkTypeOptions.map((item) => {
																const sinkLabel = sinkType[item as keyof typeof sinkType]
																return(
																<Option className="piStateSelectItem" key={item} label={sinkLabel}>
																	<div className={styles.sinkLabel}> 
																		<span>{sinkLabel}</span>
																	</div>
																</Option>
															)})
														}
													</Select>
												</div>
											</div>)}
											<div className={styles.piSelect}>
												<label>State</label>
												<div className={styles.piSelectStateWrapper}>
													<Select
														style={{width: '100%'}}
														size="large"
														optionLabelProp="label"
														mode="multiple"
														className="piStateSelect"
														value={piSelectedStates}
														showArrow
														dropdownRender={menu => (
															<>
																<div className={styles.piSelectSelectAll} onMouseDown={(e) => e.preventDefault()} onClick={
																	() => {
																		setPiSelectedStates(piSelectedStates.length === numberOfStates ? [] : Object.keys(PiRequestStates));
																	}
																}>
																	<Checkbox
																		partiallySelected={piSelectedStates.length !== numberOfStates && piSelectedStates.length > 0}
																		checked={piSelectedStates.length === numberOfStates}
																		color="gold"
																		onClick={(e) =>{e.preventDefault()}}
																	/>
																	Select All
																</div>
																{menu}
															</>
														)}
														suffixIcon={<img src="/icons/data-browser/down.svg"/>}
														onChange={(v: string[]) => {
															setPiSelectedStates(v);
														}}
													>
														{
															(Object.keys(PiRequestStates) as (keyof typeof PiRequestStates)[]).map((state) => (
																<Option className="piStateSelectItem" key={state} label={PiRequestStates[state]}>
																	<div className={classNames(styles.requestState, styles[PiRequestStates[state]])}>
																		<span>{PiRequestStates[state]}</span>
																	</div>
																</Option>
															))
														}
													</Select>
												</div>
											</div>
											<div className={styles.piSelect}>
												<label>Show requests created</label>
												<Select
													className="piRangeSelect"
													size="large"
													defaultValue={'all-time'}
													suffixIcon={<img src="/icons/data-browser/down.svg"/>}
													onChange={handleCreatedDate}
												>
													{
														REQUEST_TIME_RANGES.map(time => (
															<Option className="piRangeSelectItem"  key={time.key}>{time.label}</Option>
														))
													}
												</Select>
											</div>
											<div className={classNames(styles.piRequestCustomDateRange, {[styles.show]: showDateRange})}>
												<CustomDateTimePicker
													startRangeLabel="Date Range"
													type="range"
													value={createdDateRange} 
													setRangeValue={handleSetCreatedDateRange}
													disableFutureDates
												/>
											</div>
										</div>
									</ShowWhenTrue>
								</ShowWhenTrue>
								{sourceDataForTable.length ? (
									<>
										{activeRecordsMetadata.connectionType ===
										ConnectionTypes["NiFi Managed"] || activeRecordsMetadata.connectionType=='Edge_Managed_Oracle'
										|| activeRecordsMetadata.connectionType=='Edge_Managed_FTP'? (
											<PiManagedRequestsTable
												data={tableContent}
												refreshActivePrRecords={
													refreshActivePrRecords
												}
												viewRequest={handleViewRequest}
												viewRequestLog={
													handleViewRequestLog
												}
												activeSortColumn={
													activeSortColumn
												}
												setActiveSortColumn={
													handleSetActiveSortColumn
												}
												loading={showRefreshPiLoader}
												viewData={handleViewData}
												viewLogs={handleViewLogs}
												viewProgress={handleViewProgress}
												connectionType={activeRecordsMetadata.connectionType}
												sourceType={activeRecordsMetadata.sourceType}
											/>
										) : (
											<AdjustToCellSizeTable
												data={sourceDataForTable}
											/>
										)}
										<TablePagination
											handlePageChange={handlePageChange}
											handleItemsPerPageOptionClick={
												handlePageOptionClick
											}
											totalNoOfRecords={
												recordsInfo.totalCount
											}
											numberOfItemsPerPage={
												numberOfItemsPerPage
											}
											showNumberOfItemsPerPageDropdown
										/>
									</>
								) : (
									<div className="noworkflows__msg">
										No Data Available
									</div>
								)}
							</div>
							{/* <div className={classNames('queryEditorMaster', {'hide': activeInsideTab !== dataBrowserInsideTabs.query})}>
                            <button 
                                className="btn__queryBuilder"
                            >
                                Query Builder 
                                <OpenQueryBuilderIcon />
                            </button>
                            <div className="editorHeader">
                                <button
                                    onClick={handleCopyEditorText}
                                >
                                    <CopyIcon /> Copy
                                </button>
                                <button
                                    onClick={handleClearEditor}
                                >
                                    <ClearIcon /> Clear
                                </button>
                            </div>
                            <Form
                                initialValues={{}}
                                onSubmit={() => {return;}}
                            >
                                <EditorField
                                    name="test" 
                                />
                            </Form>
                            <div className="actionButtons__box">
                                <button
                                    className="btn-md btn-yellow"
                                >
                                    Run
                                </button>
                                <button
                                    className="btn-md btn-yellow-transparent"
                                >
                                    Save
                                </button>
                            </div>
                            <div className="queryList__box">
                                <ul className="switchable__tabs__outer">
                                    {queryBrowserTabsList.map(_tab => (
                                        <li 
                                            className={classNames('switchable__tab', {'switchable__tab--active': _tab === activeQueryBrowserTab})}
                                            onClick={() => setActiveQueryBrowserTab(_tab)}
                                            key={_tab}
                                        >
                                            {_tab}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            
                        </div> */}
						</div>
					) : (
						<div className="noworkflows__msg databrowserEmptyCanvas">
							<ul>
								<li
									onClick={handleNewConnectionClick}
									className="workflow__actions"
								>
									Create a New Connection
								</li>
							</ul>
						</div>
					)}
				</div>

				{showBottomHelpTab && (
					<BottomPanel
						open={bottomTabOpen}
						onMinimize={() => setBottomTabOpen(false)}
						tabIndex={bottomTabIndex}
					/>
				)}
			</div>
			<ColumnManagementRhs />
			<QueryEditorBuilderModal
				onSubmit={handleQueryEditorBuilderSubmit}
			/>
			<NewEditPiRequestModal
				activeRecordsMetadata={activeRecordsMetadata}
				refreshActivePrRecords={refreshActivePrRecords}
				{...piRequestModalInfo}
			/>
			<ShowWhenTrue show={!showAllLogsView}>
				<RequestLogsModal
					property={piRequestModalInfo.property}
					logs={(piRequestModalInfo.selectedPiRequestInfo ? piRequestModalInfo.selectedPiRequestInfo[piRequestModalInfo.property] : nodeStatus?.error) || activeRecordsMetadata.managedNodeInfo?.error||[]}
					name={(piRequestModalInfo.selectedPiRequestInfo?.id || 'connection') as string} />
			</ShowWhenTrue>
			<ShowWhenTrue show={showAllLogsView}>
				<RequestLogsModal
					property={piRequestModalInfo.property}
					logs={(piRequestModalInfo.selectedPiRequestInfo ? piRequestModalInfo.selectedPiRequestInfo[piRequestModalInfo.property] : activeRecordsMetadata.managedNodeInfo?.logs) ||[]}
					name={(piRequestModalInfo.selectedPiRequestInfo?.id || 'connection') as string} />
			</ShowWhenTrue>
			
			<ViewRequestDataModal 
				isOpen={isViewDataModalOpen} 
				data={viewDataModal} 
				onClose={onViewRequestDataClose}/>
			<ViewLogsRequestDataModal 
				isOpen={isViewLogsModalOpen} 
				data={viewLogsModalData} 
				onClose={onViewLogsRequestDataClose}
				title={`Request # ${currPiRequest?.id} (${currPiRequest?.requestName}) Status Logs`}
			/>
			<ViewProgressRequestDataModal 
				isOpen={progressModalOpen} 
				data={viewProgressModalData} 
				onClose={onProgressRequestDataClose}
				title={`Request # ${currProgressRequest?.id} Progress Logs`}
			/>
		</section>
	);
};

export default DataSourceBrowser;